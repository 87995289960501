import React, { useRef, useState } from 'react';

import BogleText from 'components/common/BogleText';
import BogleInput from 'components/common/BogleInput';

import { InfoFooter } from '..';
import { BodyContainer, InfoStepProps, InputBoxContainer } from './Info';
import { useMutation } from '@apollo/client';
import { SIGNUP, SignupResponse } from 'services/graphql/User';
import { useNavigate } from 'react-router-dom';

const nicknamePattern = /^[가-힣]{2,8}$/;

const InfoStep3: React.FC<InfoStepProps> = ({ setAgreeStep, registerUserData }) => {
  const nickName = useRef<string>('');

  const [userNickNameState, setUserNickNameState] = useState<'normal' | 'typing' | 'success' | 'error'>('normal');

  const validateNickName = (nick: string) => nicknamePattern.test(nick);

  const navigate = useNavigate();

  const btnNextClickStep3 = async () => {

    const isNickNameValid = validateNickName(nickName.current);

    setUserNickNameState(isNickNameValid ? 'success' : 'error');

    if (isNickNameValid) {
      setAgreeStep(4);
      
      registerUserData.current.nickname = nickName.current;
      console.log(registerUserData);

      const username = registerUserData.current.loginId;
      const email = registerUserData.current.email;
      const password = registerUserData.current.password;
      const nickname = registerUserData.current.nickname;
      const requireTerm = registerUserData.current.requireTerm;
      const adConsentTerm = registerUserData.current.optionTerm1;
      const thirdPartyConsentTerm = registerUserData.current.optionTerm2;

      console.log(username);
      console.log(email);
      console.log(password);
      console.log(nickname);
      console.log(requireTerm);
      console.log(adConsentTerm);
      console.log(thirdPartyConsentTerm);
      await RegisterMutation({ variables: { username , email, password, nickname, requireTerm, adConsentTerm, thirdPartyConsentTerm } });

    }
  };

  const [RegisterMutation] = useMutation<SignupResponse>(SIGNUP, {
    onCompleted: (data) => {
      console.log(data);

      localStorage.setItem('accessToken', data.signup.accessToken);
      document.cookie = `refreshToken=${data.signup.refreshToken}; HttpOnly; Secure; SameSite=Strict;`;

      console.log("회원가입 성공");

      navigate("/login");
      
    },
    onError: (error) => {
      console.error('Login failed:', error.message);
    },
  });
  
  return (
    <>
      {/* 메인 */}
      <BodyContainer>
        <BogleText color='#000000' fontSize='1.375rem' style={{ lineHeight: '1.875rem' }}>
          닉네임을<br />입력해 주세요.
        </BogleText>

        <InputBoxContainer>
          <BogleInput
            state={userNickNameState}
            type='text'
            value={nickName.current}
            title='닉네임'
            placeholder='닉네임 (한글 2~8글자 이내)'
            errorText='닉네임은 (한글 2~8글자 이내)입니다.'
            maxLength={8}
            onChange={(e) => {
              nickName.current = e.target.value;
              setUserNickNameState(e.target.value.length > 0 ? 'typing' : 'normal');
            }}
          />
        </InputBoxContainer>
      </BodyContainer>

       {/* 하단 */}
      <InfoFooter
        background={nickName.current !== ''? '#A57BDB' : '#EEEEEE'}
        btnkClick={btnNextClickStep3}
      />
      
    </>
  );
};

export default InfoStep3;
