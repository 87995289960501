import React from 'react';

import styled from 'styled-components';

import BogleButton from 'components/common/BogleButton';
import SearchBar from 'components/common/SearchBar';

const HeaderContainer = styled.div`
    position: relative;
    width: 100%;
    height: 5.938rem;
    text-align: center;
`;

const SearchBarContainer = styled.div`
    position: absolute;
    width: 82%;
    height: 3rem;
    bottom: 0em;
    right: 0.25rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

interface AppBar1Props {
    title?: string;
    actionString?: string;
    placeholder?: string;
    backAction?: () => void;
}

const AppBar2: React.FC<AppBar1Props> = ({ placeholder, backAction }) => {
    return (
        <HeaderContainer>
            <BogleButton onClick={backAction} width='4rem' height='3rem' 
                style={{ position: 'absolute', left: '0em', bottom: '0em', justifyContent: 'center'}}>
                <img src='/images/chevron-left.svg' />
            </BogleButton>
            <SearchBarContainer>
                <SearchBar width='100%' placeholder={placeholder} ></SearchBar>
            </SearchBarContainer>
        </HeaderContainer>
    );
};

export default AppBar2;
