import React, { useRef, useState } from 'react';

import styled from 'styled-components';

import BogleText from 'components/common/BogleText';
import BogleInput from 'components/common/BogleInput';
import { InfoFooter } from '..';
import { BodyContainer, InfoStepProps } from './Info';

const InputBoxContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0rem;
  width: 100%;
  position: relative;
`;

const InfoStep2: React.FC<InfoStepProps> = ({ setAgreeStep, registerUserData }) => {
  const email = useRef<string>('');

  const [userEmailState, setUerEmailState] = useState<'normal' | 'typing' | 'success' | 'error'>('normal');

  const btnNextClickStep2 = () => {
    if (email.current.length >= 0)
    {
      setUerEmailState('success');
    }
    else
    {
      setUerEmailState('error');
    }

    if (userEmailState === 'success') {
      registerUserData.current.email = email.current;
      setAgreeStep(3);
    }
  };

  return (
    <>
      {/* 메인 */}
      <BodyContainer>
        <BogleText color='#000000' fontSize='1.375rem' style={{ lineHeight: '1.875rem' }}>
          이메일을<br />입력해 주세요.
        </BogleText>

        <InputBoxContainer>
          <BogleInput
            state={userEmailState} 
            type='email'
            value={email.current}
            title='이메일'
            placeholder='이메일'
            maxLength={20}
            onChange={(e) => {
              email.current = e.target.value;
              setUerEmailState(e.target.value.length > 0 ? 'typing' : 'normal');
            }}
          />
        </InputBoxContainer>
      </BodyContainer>

      {/* 하단 */}
      <InfoFooter
        background={email.current !== '' ? '#A57BDB' : '#EEEEEE'}
        btnkClick={btnNextClickStep2}
      />
    </>
  );
};

export default InfoStep2;
