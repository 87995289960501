import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import BogleText from './BogleText';

const borderColors: Record<string, string> = {
  normal: '#A9B1BC',
  typing: '#32373E',
  success: '#A57BDB',
  error: '#FA3838',
}

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 100%;
  position: relative;
  height: 5.375rem;
`;

const InputGroup = styled.div`
  display: flex;
  box-sizing: border-box;
  width: 100%;
  height: 2.813rem;
  flex-direction: column;
  position: absolute;
  top: 1rem;
  border-color: #A9B1BC;
`;

const Label = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.25rem;
  color: #1f2326;

  position:absolute;
  pointer-events:none;
  left:15px;
  top:10px;
  transition:0.2s ease all;
`;

const Bar = styled.div<{ $state: string }>`
  position: relative;
  display: block;
  width: 100%;
  bottom: 0px;
  border-bottom: ${({ $state }) => `1px solid ${borderColors[$state]}` || '1px solid #A9B1BC'};

  &::before {
    content: '';
    width: 0;
    right: 50%; 
    bottom: -1px; 
    position: absolute;
    border-bottom: 1px solid #32373E;
    transition: 0.2s ease all;
  }

  &::after {
    content: '';
    width: 0;
    left: 50%;
    bottom: -1px; 
    position: absolute;
    border-bottom: 1px solid #32373E;
    transition: 0.2s ease all;
  }
`;

const Input = styled.input`
  display: block;
  width: 100%;
  height: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 12.8px;
  line-height: 1.25rem;
  color: #1f2326;
  border: none;
  outline: none;

  &:focus ~ ${Label},
  &:valid ~ ${Label} {
    left: 0px;
    top:-17px;

    font-family: 'Pretendard JP';
    font-weight: 600;
    font-size: 13px;
    color: #1F2326;
  }

  &:focus ~ ${Bar}::before,
  &:focus ~ ${Bar}::after {
    background: #32373E;
    width: 50%;
  }
`;

const RightIconImg = styled.img`
  position: absolute;
  width: 20px;
  top: 29px;
  right: 12px;
`;

const ErrorText = styled(BogleText)`
  color: #FA3838;
  font-size: 0.625rem;
  width: 100%;
  height: 1.25rem;
  padding: 0 0.5rem;
  justify-content: left;
  align-items: baseline;
  display: flex;
  position: absolute;
  bottom: 0rem;
`;

const EmailDropdownList = styled.div<{ $isTopDown: boolean }>`
  display: ${({ $isTopDown }) => $isTopDown === false ? 'none' : 'block'};
  position: absolute;
  top: 100%;
  left: -1px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #777E88;
  border-top: none;
  border-radius: 0 0 10px 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const EmailDropdownItem = styled.div`
  padding: 0.9375rem 0.9375rem;
  font-family: 'Pretendard JP';
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: #1f2326;
  cursor: pointer;
  
  &:hover {
    background-color: #f2f2f2;
  }
`;

interface BogleInputProps {
  state: 'normal' | 'typing' | 'success' | 'error';
  type: 'email' | 'text' | 'password';
  value: string;
  title?: string;
  placeholder?: string;
  maxLength?: number;
  errorText?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
  onBlur?: () => void;
}

const BogleInput: React.FC<BogleInputProps> = ({ state, type, value, title, maxLength = 20, errorText, onChange, onFocus, onBlur }) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isTopDown, setIsTopDown] = useState<boolean>(false);
  
  const emailDomains = ['naver.com', 'nate.com', 'gmail.com', 'daum.net'];

  useEffect(() => {
    setInputValue(value);
    onChange({ target: { value: value, type: type } } as React.ChangeEvent<HTMLInputElement>);
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.slice(0, maxLength);
    e.target.value = value;

    setInputValue(value);
    setIsTopDown(value.includes('@'));

    onChange(e);
  };

  const clickedShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const inputRightIcon = (type: string, state: string) => {
    if (state === "error") {
      return (<RightIconImg src="/images/alert-circle.svg" />);
    }
    else if (type === "password" && inputValue) {
      return (<RightIconImg src='/images/eye-open.png' onClick={clickedShowPassword} />);
    }
    else if (type === 'email') {
      return (<></>);  
    }
    else if (state === "success") {
      return (<RightIconImg src="/images/check-01.svg" />);
    }
  };

  return (
    <InputContainer>
      <InputGroup>
        <Input 
          type={type === 'password' ? showPassword ? 'text' : 'password' : type}
          // placeholder={placeholder}
          value={inputValue}
          onChange={handleInputChange}
          onFocus={onFocus}
          onBlur={onBlur}
          required 
        />

        <Bar $state={state}/>
        <Label>{title}</Label>

        {type === 'email' &&
          <EmailDropdownList $isTopDown={isTopDown}>
            {emailDomains.map((domain) => {
              const localPart = inputValue.split('@')[0];
              const fullEmail = `${localPart}@${domain}`;

              return (
                <EmailDropdownItem
                  key={domain}
                  onClick={() => {
                    setInputValue(fullEmail);
                    onChange({ target: { value: fullEmail } } as React.ChangeEvent<HTMLInputElement>);
                    setIsTopDown(!isTopDown);
                  }}
                >
                  {fullEmail}
                </EmailDropdownItem>
              );
            })}
          </EmailDropdownList>
        }
      </InputGroup>
      {inputRightIcon(type, state)}
      {state === 'error' && errorText && <ErrorText>{errorText}</ErrorText>}
    </InputContainer>
  );
};

export default BogleInput;