import React from 'react';
import { BoardGameData } from 'services/BogleUserApi';
import styled from 'styled-components';

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  gap: 16px;
  width: 342px;
  height: 58px;
  border-radius: 8px;
`;

const Image = styled.div<{ $imageUrl: string }>`
  width: 58px;
  height: 58px;
  background: url(${props => props.$imageUrl}) no-repeat center center;
  background-size: cover;
  border-radius: 8px;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  gap: 10px;
  width: 268px;
  height: 46px;
`;

const GameName = styled.div`
  width: 268px;
  height: 20px;
  font-family: 'Pretendard JP', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #32373e;
`;

const TagsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  gap: 8px;
  width: 268px;
  height: 16px;
`;

const Tag = styled.div<{ background: string }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 6px;
  gap: 8px;
  height: 16px;
  background: ${props => props.background};
  border-radius: 999px;
  font-family: 'Pretendard JP', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  color: #32373e;
`;

const tagColors: {[key: string]: string} = {
  '파티': '#FFE2DD',
  '경매': '#DBEDDB',
};

interface SearchListItemProps {
  boardGameData: BoardGameData;
  onClikedItem?: (boardGameData: BoardGameData) => void;
}

const SearchListItem: React.FC<SearchListItemProps> = ({ boardGameData, onClikedItem }) => {
  return (
    <ItemWrapper onClick={() => {onClikedItem && onClikedItem(boardGameData)}}>
      <Image $imageUrl={boardGameData.imageUrl} />
      <InfoWrapper>
        <GameName>{boardGameData.name}</GameName>
        <TagsWrapper>
          {boardGameData.tags.map((tag, index) => (
            <Tag key={index} background={tagColors[tag]}>
              {tag}
            </Tag>
          ))}
        </TagsWrapper>
      </InfoWrapper>
    </ItemWrapper>
  );
};

export default SearchListItem;
