import React from 'react';
import styled from 'styled-components';

interface BogleTextProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
  width?: string;
  height?: string;
  fontSize?: string;
  color?: string;
  $justifycontent?: 'left' | 'center' | 'right';
}

const StyledBogleText = styled.div<BogleTextProps>`

  color: ${({ color }) => color || '#FFFFFF'};
  width: ${({ width }) => width || 'auto'};
  height: ${({ height }) => height || 'auto'};
  justify-content: ${({ $justifycontent }) => $justifycontent || 'center'};
  font-size: ${({ fontSize }) => fontSize || '0.938rem'};

  font-family: 'Pretendard JP';
  font-style: normal;
  font-weight: 600;

  display: block;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const BogleText: React.FC<BogleTextProps> = ({ children, ...props }) => {
  return (
    <StyledBogleText {...props}>
      {children}
    </StyledBogleText>
  );
};

export default BogleText;
