import React, { useRef, useState } from 'react';

import BogleInput from 'components/common/BogleInput';
import BogleText from 'components/common/BogleText';
import { InfoFooter } from '..';
import { BodyContainer, InfoStepProps, InputBoxContainer } from './Info';

const loginIdPattern = /^[a-zA-Z0-9]{6,13}$/;
const passwordPattern = /^(?=.*[A-Za-z])(?=.*\d|.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{10,16}$/;

const InfoStep1: React.FC<InfoStepProps> = ({ setAgreeStep, registerUserData }) => {
  const loginId = useRef<string>('');
  const password = useRef<string>('');
  const password2 = useRef<string>('');

  const [loginIdState, setLoginIdState] = useState<'normal' | 'typing' | 'success' | 'error'>('normal');
  const [password1State, setPassword1State] = useState<'normal' | 'typing' | 'success' | 'error'>('normal');
  const [password2State, setPassword2State] = useState<'normal' | 'typing' | 'success' | 'error'>('normal');

  const validateLoginId = (id: string) => loginIdPattern.test(id);
  const validatePassword = (pwd: string) => passwordPattern.test(pwd);
  const validatePasswordMatch = (pwd1: string, pwd2: string) => pwd1 === pwd2;

  const btnNextClickStep1 = () => {
    const isLoginIdValid = validateLoginId(loginId.current);
    const isPasswordValid = validatePassword(password.current);
    const isPasswordMatch = validatePasswordMatch(password.current, password2.current);

    setLoginIdState(isLoginIdValid ? 'success' : 'error');
    setPassword1State(isPasswordValid ? 'success' : 'error');
    setPassword2State(isPasswordMatch ? 'success' : 'error');

    if (isLoginIdValid && isPasswordValid && isPasswordMatch) {
      registerUserData.current.loginId = loginId.current;
      registerUserData.current.password = password.current;
      setAgreeStep(2);
    }
  };

  return (
    <>
      <BodyContainer>
        <BogleText color='#000000' fontSize='1.375rem' style={{ lineHeight: '1.875rem' }}>
          아이디와 비밀번호를<br /> 입력해주세요.
        </BogleText>

        <InputBoxContainer>
          <BogleInput
            state={loginIdState}
            type='text'
            value={loginId.current}
            title='아이디'
            placeholder='아이디 (영문 대소문자, 숫자 사용 6~13자리 이내)'
            errorText='아이디는 영문 대소문자와 숫자만 사용 가능하며, 6~13자리여야 합니다.'
            onChange={(e) => {
              loginId.current = e.target.value;
              setLoginIdState(e.target.value.length > 0 ? 'typing' : 'normal');
            }}
          />
          <BogleInput
            state={password1State}
            type='password'
            value={password.current}
            title='비밀번호'
            placeholder='비밀번호 (영문, 숫자 또는 특수문자 2가지 조합 10자리)'
            maxLength={16}
            errorText='비빌번호는 영문, 숫자 또는 특수문자 2가지를 조합하여야 합니다.'
            onChange={(e) => {
              password.current = e.target.value;
              setPassword1State(e.target.value.length > 0 ? 'typing' : 'normal');
            }}
          />
          <BogleInput
            state={password2State}
            type='password'
            value={password2.current}
            title='비밀번호 확인'
            placeholder='비밀번호 확인'
            maxLength={16}
            errorText='비밀번호가 일치하지 않습니다.'
            onChange={(e) => {
              password2.current = e.target.value;
              setPassword2State(e.target.value.length > 0 ? 'typing' : 'normal');
            }}
          />
        </InputBoxContainer>
      </BodyContainer>

      <InfoFooter
        background={
          loginIdState !== 'normal' &&
          password1State !== 'normal' &&
          password2State !== 'normal'
          ? '#A57BDB' : '#EEEEEE'}
        btnkClick={btnNextClickStep1}
      />
    </>
  );
};

export default InfoStep1;
