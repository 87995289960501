import React, { useEffect } from 'react';

import styled, { keyframes } from 'styled-components';

const bottomSheetUp = keyframes`
    from {
        opacity: 0;
        transform: translateY(100%);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

const Dim = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.2);
`;

const BottomSheetContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -0.563rem;
    background: #ffffff;
    border-radius: 1rem 1rem 0 0;

    animation: ${bottomSheetUp} 0.3s ease;
`;

interface BottomSheetProps {
    isBottomSheetOpen: boolean;
    setIsBottomSheetOpen: React.Dispatch<React.SetStateAction<boolean>>;
    children: React.ReactNode;
}

const BottomSheet: React.FC<BottomSheetProps> = ({ isBottomSheetOpen, setIsBottomSheetOpen, children }) => {
    useEffect(() => {
        if (isBottomSheetOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }

        return () => {
            document.body.style.overflow = '';
        };
    }, [isBottomSheetOpen]);

    if (!isBottomSheetOpen) {
        return null;
    }

    const onClickedDim = () => {
        setIsBottomSheetOpen(false);
    };

    const onClickBottomSheet = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
    };

    return (
        <Dim onClick={onClickedDim}>
            <BottomSheetContainer onClick={onClickBottomSheet}>
                {children}
            </BottomSheetContainer>
        </Dim>
    );
};

export default BottomSheet;
