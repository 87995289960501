import React from "react";
import { useNavigate } from "react-router-dom";

import styled from 'styled-components';

import AppBar2 from "components/appBar/AppBar2";
import SearchListItem from "components/common/SearchList";
import { BoardGameData } from "services/BogleUserApi";

const ListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    gap: 1.75rem;
    padding: 1.25rem;
`;

const Search: React.FC = () => {
    const navigate = useNavigate();

    const btnBackClick = () => {
        navigate(-1);
    };

    const games = [
        {
            imageUrl: '/images/SearchListImg.png',
            name: '보드게임 이름1',
            tags: ['파티', '경매']
        },
        {
            imageUrl: '/images/SearchListImg.png',
            name: '보드게임 이름2',
            tags: ['파티', '경매']
        },
        {
            imageUrl: '/images/SearchListImg.png',
            name: '보드게임 이름3',
            tags: ['파티', '경매']
        }
      ];

    const onClickedBoardgameList = (boardGameData: BoardGameData) => {
        navigate('/review/write', { state: boardGameData });
    };

    return (
    <>
        <AppBar2 backAction={btnBackClick} placeholder='게임명을 검색해주세요'/>
        <ListWrapper>
            {games.map((game, index) => (
            <SearchListItem
                key={index}
                boardGameData={game}
                onClikedItem={() => onClickedBoardgameList(game)}
            />
        ))}
        </ListWrapper>
    </>
    );
}

export default Search;
