import React, { useRef } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import styled from 'styled-components';

import Agree from './Agree';
import Info from './info/Info';
import BogleButton from 'components/common/BogleButton';
import BogleText from 'components/common/BogleText';
import { RegisterUserData } from 'services/BogleUserApi';

export const BottomContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  left: 0em;
  bottom: 0em;
`;

interface InfoFooterProps {
  btnkClick: () => void;
  background: string;
}

export const InfoFooter: React.FC<InfoFooterProps> = ({ btnkClick, background }) => {
  return (
      <BottomContainer>
        <BogleButton onClick={btnkClick} width='100%' height='3rem' $background={background}>
          <BogleText color='#FFFFFF' fontSize='0.938rem' style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
            다음
          </BogleText>
        </BogleButton>
      </BottomContainer>
  );
};

const SignupRoutes: React.FC = () => {
  const userDataRef = useRef<RegisterUserData>({
    loginType: 'BASIC',
    loginId: '',
    password: '',
    email: '',
    nickname: '',
    requireTerm: false,
    optionTerm1: false,
    optionTerm2: false,
  });

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/signup/agree" />} />
      <Route path="/agree" element={<Agree registerUserData={userDataRef} />} />
      <Route path="/info" element={<Info registerUserData={userDataRef}/>} />
  </Routes>
  );
};

export default SignupRoutes;
