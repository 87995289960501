import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { ApolloProvider } from '@apollo/client';

import Layout from './components/Layout';
import Login from './pages/login/Login';
import SignupRoutes from './pages/signup';
import HomeRoutes from 'pages/home';
import ReviewRoutes from 'pages/review';
import apolloClient from 'services/graphql/ApolloClient';
import GameRoutes from 'pages/game';
import ProfileRoutes from 'pages/profile';
import IndexPage from 'pages';

const App: React.FC = () => {
  return (
    <ApolloProvider client={apolloClient}>
      <Router>
        <Routes>
          <Route path="/" element={<Layout />}>
            {/* public routes */}
            <Route path="" element={<IndexPage />} />
            <Route path="login" element={<Login />} />
            <Route path="signup/*" element={<SignupRoutes />} />
            <Route path="home/*" element={<HomeRoutes />} />
            <Route path="review/*" element={<ReviewRoutes />} />
            <Route path="game/*" element={<GameRoutes />} />
            <Route path="profile/*" element={<ProfileRoutes />} />
          </Route>
        </Routes>
      </Router>
    </ApolloProvider>
  );
};

export default App;
