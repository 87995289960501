import React, { useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
    from {
        opacity: 0;
        transform: scale(0.75);
    }
    to {
        opacity: 1;
        transform: scale(1);
  }
`;

const fadeOut = keyframes`
    from {
        opacity: 1;
        transform: scale(1);
    }
    to {
        opacity: 0;
        transform: scale(0.75);
    }
`;

const Dim = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.5);
`;

const DialogContainer = styled.div<{ $show: boolean }>`
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 302px;
    left: calc(50% - 151px);
    top: calc(50% - 85px);
    background: #FFFFFF;
    border-radius: 24px;
    padding: 24px 0px 16px;
    gap: 16px;
    z-index: 4;
    opacity: ${({ $show }) => ($show ? 1 : 0)};
    transform: ${({ $show }) => ($show ? 'scale(1)' : 'scale(0.75)')};
    transition: opacity 0.3s ease, transform 0.3s ease;
    animation: ${({ $show }) => ($show ? fadeIn : fadeOut)} 0.3s ease;
`;

const TextGroup = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 16px;
    gap: 8px;
`;

const Title = styled.div`
    font-family: 'Pretendard JP';
    font-weight: 600;
    font-size: 19px;
    line-height: 28px;
    color: #1F2326;
`;

const Description = styled.div`
    font-family: 'Pretendard JP';
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: #777E88;
`;

const ButtonGroup = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0px 16px;
`;

const Button = styled.button<{ $primary?: boolean }>`
    width: 131px;
    height: 56px;
    border-radius: 16px;
    border: none;
    font-family: 'Pretendard JP';
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ $primary }) => ($primary ? '#FFFFFF' : '#777E88')};
    background: ${({ $primary }) => ($primary ? '#A57BDB' : '#EEEEEE')};
`;

interface DialogProps {
    title: string;
    description: string;
    leftButtonText: string;
    rightButtonText: string;
    show: boolean;
    onLeftClick: () => void;
    onRightClick: () => void;
}

const DialogTwoButton: React.FC<DialogProps> = ({ title, description, leftButtonText, rightButtonText, show, onLeftClick, onRightClick }) => {
    useEffect(() => {
        document.body.style.overflow = show ? 'hidden' : '';
        
        return () => {
            document.body.style.overflow = '';
        };
    }, [show]);

    return (
        <Dim>
            <DialogContainer $show={show}>
                <TextGroup>
                    <Title>{title}</Title>
                    <Description>{description}</Description>
                    </TextGroup>
                    <ButtonGroup>
                    <Button $primary={false} onClick={onLeftClick}>{leftButtonText}</Button>
                    <Button $primary={true} onClick={onRightClick}>{rightButtonText}</Button>
                </ButtonGroup>
            </DialogContainer>
        </Dim>
    );
};

export default DialogTwoButton;
