import React from 'react';
import styled from 'styled-components';

import Tag from 'components/common/BogleTag';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    gap: 0.75rem;
    width: 7.5rem;
    height: 12.5rem;
`;

const ImageFrame = styled.div<{ $imageUrl: string }>`
    width: 7.5rem;
    height: 7.5rem;
    background: url(${props => props.$imageUrl}) no-repeat center center;
    background-size: cover;
    border-radius: 0.5rem;
    position: relative;
`;

const Badge = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0.375rem;
    gap: 0.625rem;
    position: absolute;
    height: 1rem;
    left: 0.4375rem;
    top: 0.4375rem;
    background: #A57BDB;
    border-radius: 100px;
`;

const BadgeLabel = styled.div`
    height: 1rem;
    font-family: 'Pretendard JP', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 0.8125rem;
    line-height: 1rem;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
`;

const DetailsFrame = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 0.125rem;
    gap: 0.5rem;
    width: 7.5rem;
    height: 4.25rem;
`;

const NameFrame = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    gap: 0.125rem;
    width: 7.25rem;
    height: 2.75rem;
`;

const GameName = styled.div`
    width: 7.25rem;
    height: 1.375rem;
    font-family: 'Pretendard JP', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 0.9375rem;
    line-height: 1.375rem;
    display: flex;
    align-items: center;
    color: #32373E;
`;

const RecordCount = styled.div`
    width: 7.25rem;
    height: 1.25rem;
    font-family: 'Pretendard JP', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.8125rem;
    line-height: 1.25rem;
    display: flex;
    align-items: center;
    color: #777E88;
`;

const TagsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0;
    gap: 0.375rem;
    width: 7.875rem;
    height: 1rem;
`;

interface BoardGameList2Props {
    imageUrl: string;
    gameName: string;
    recordCount: number;
    badgeLabel: string;
    tags: string[];
}

const BoardGameList_Vertical: React.FC<BoardGameList2Props> = ({ imageUrl, gameName, recordCount, badgeLabel, tags }) => {
    return (
    <Container>
        <ImageFrame $imageUrl={imageUrl}>
        <Badge>
            <BadgeLabel>{badgeLabel}</BadgeLabel>
        </Badge>
        </ImageFrame>
        <DetailsFrame>
        <NameFrame>
            <GameName>{gameName}</GameName>
            <RecordCount>{recordCount}개의 기록</RecordCount>
        </NameFrame>
        <TagsWrapper>
            {tags.map((tag, index) => (
            <Tag key={index} tagText={tag} />
            ))}
        </TagsWrapper>
        </DetailsFrame>
    </Container>
    );
};

export default BoardGameList_Vertical;
