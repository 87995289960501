import React from 'react';
import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';

import AppBar1 from 'components/appBar/AppBar1';
import BogleButton from 'components/common/BogleButton';
import BogleText from 'components/common/BogleText';


const ProfileEditContainer = styled.div`
  width: 100%;
  height: auto;
  min-height: calc(100% - 6.375rem);
  position: relative;
  padding-bottom: 6.375rem;
`;

const UserProfileWapper = styled.div`
  display: flex;
  position: relative;
  width: 88px;
  left: calc(50% - 44px);
  align-items: center;
  padding: 32px 0px 48px 0px;
  justify-content: center;
`;

const UserProfileImg = styled.img`
  display: flex;
  width: 88px;
`;

const UserProfileEditImg = styled.img`
  display: flex;
  position: absolute;
  width: 36px;
  left: 64px;
  top: 89px;
`;

const EditBoxGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  padding: 0px 24px 48px 24px;
`;

const EditBoxTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 8px;
  gap: 4px;
  height: 22px;

  //text
  font-family: 'Pretendard JP';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: #32373E;
`;

const EditBoxInfo = styled.input`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 20px;
  gap: 16px;
  isolation: isolate;
  width: calc(100% - 40px);
  height: 24px;
  background: #F6F8F9;
  border-radius: 12px;
  border: none;

  //text
  font-family: 'Pretendard JP';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
  color: #32373E;

  &::placeholder {
    //text
    font-family: 'Pretendard JP';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    color: #A9B1BC;
  }
`;

const ProfileEdit: React.FC = () => {
  const navigate = useNavigate();

  const btnBackClick = () => {
    navigate(-1);
  };

  const onClickedEditOk = () => {
    //df
  };

  return (
    <ProfileEditContainer>
      <AppBar1 title='프로필 수정' backAction={btnBackClick}/>

      <UserProfileWapper>
        <UserProfileImg src='/images/profile/user-profile.png'/>
        <UserProfileEditImg src='/images/profile/profile-edit-right-bottom-img.svg'/>
      </UserProfileWapper>

      <EditBoxGroup>
        <EditBoxTitle>닉네임 </EditBoxTitle>
        <EditBoxInfo placeholder='닉네임을 입력해 주세요.'/>
      </EditBoxGroup>

      <EditBoxGroup>
        <EditBoxTitle>자기소개 </EditBoxTitle>
        <EditBoxInfo placeholder='자기 소개를 입력해 주세요.'/>
      </EditBoxGroup>

      <BogleButton
        width="90%"
        height="3.5rem"
        $background="#A57BDB"
        radius="1rem"
        style={{ position: 'fixed', left: '5%', bottom: '0', justifyContent: 'center', margin: '2rem 0rem 1.563rem' }}
        onClick={onClickedEditOk}
        >
          <BogleText fontSize="1.063rem">수정 완료</BogleText>
        </BogleButton>

    </ProfileEditContainer>
  );
};

export default ProfileEdit;
