import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';

import BogleNavigation from 'components/common/BogleNavigation';
import { Header2 } from 'components/common/Header';
import BogleButton from 'components/common/BogleButton';

import DialogTwoButton from 'components/dialog/Dialog.TwoButton';

const ProfileContainer = styled.div`
  width: 100%;
  height: auto;
  min-height: calc(100% - 6.375rem);
  position: relative;
  padding-bottom: 6.375rem;
`;

const UserProfileWapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px 0px 48px 0px;
`;

const UserProfileImg = styled.img`
  display: flex;
  width: 88px;
`;

const UserProfileNameWapper = styled.div`
  display: flex;
  padding: 16px 0px 4px 0px;
`;

const UserProfileNameText = styled.div`
  font-family: 'Pretendard JP';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  text-align: justify;
  color: #32373E;
`;

const UserProfileIntroductionText = styled.div`
  font-family: 'Pretendard JP';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  text-align: justify;
  color: #777E88;
`;

const ContourLine = styled.div`
  disflay: flex;
  background: #F6F8F9;
  height: 6px;
`;

const UserSupportWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 20px 24px 20px;
  gap: 30px;
`;

const UserSupportText = styled.div`
  font-family: 'Pretendard JP';
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
  display: flex;
  align-items: center;

  color: #32373E;
`;

const LogoutWapper = styled.div`
  display: flex;
  padding: 24px 20px 24px 20px;
`;

const Profile: React.FC = () => {
  const [logoutPopup, setLogoutPopup] = useState<boolean>(false);

  const navigate = useNavigate();

  const onClickedUserProfile = () => {
    navigate("../edit");
  };

  const onClickedNotice = () => {
    navigate("../notice");
  };

  const onClickedFAQ = () => {
    navigate("../faq");
  };

  const onClickedInquiry = () => {
    // tbd
  };

  const onClickedTerms = () => {
    // tbd
  };

  const onClickedLogOut = () => {
    setLogoutPopup(true);
  };

  const onLClickedLogOutLeft = () => {
    setLogoutPopup(false);
  };

  const onLClickedLogOutRight = () => {
    // 로그아웃 로직
  };

  return (
    <ProfileContainer>
      <Header2 />

      <UserProfileWapper>
        <UserProfileImg src='/images/profile/user-profile.png'/>
        
        <UserProfileNameWapper>
          <BogleButton
            $ischevronright={true}
            $applyAnimation={true}
            style={{padding: '0px 28px 0 0px'}}
            onClick={onClickedUserProfile}
          >
            <UserProfileNameText>유저 닉네임</UserProfileNameText>
          </BogleButton>
        </UserProfileNameWapper>

        <UserProfileIntroductionText>
            자기소개  
          </UserProfileIntroductionText>

      </UserProfileWapper>

      <ContourLine />

      <UserSupportWrapper>
        <BogleButton
          width='100%'
          height='40px'
          $ischevronright={true}
          $applyAnimation={true}
          onClick={onClickedNotice}
        >
          <UserSupportText>공지사항 / 이벤트</UserSupportText>
        </BogleButton>

        <BogleButton
          width='100%'
          height='40px'
          $ischevronright={true}
          $applyAnimation={true}
          onClick={onClickedFAQ}
        >
          <UserSupportText>자주 묻는 질문</UserSupportText>
        </BogleButton>

        <BogleButton
          width='100%'
          height='40px'
          $ischevronright={true}
          $applyAnimation={true}
          onClick={onClickedInquiry}
        >
          <UserSupportText>1:1 문의</UserSupportText>
        </BogleButton>

        <BogleButton
          width='100%'
          height='40px'
          $ischevronright={true}
          $applyAnimation={true}
          onClick={onClickedTerms}
        >
          <UserSupportText>서비스 이용 약관</UserSupportText>
        </BogleButton>

      </UserSupportWrapper>
  
      <ContourLine />

      <LogoutWapper>
        <BogleButton
            width='100%'
            height='40px'
            $applyAnimation={true}
            onClick={onClickedLogOut}
          >
            <UserSupportText>로그아웃</UserSupportText>
          </BogleButton>
      </LogoutWapper>
      <BogleNavigation />

      {logoutPopup &&
        <DialogTwoButton
          title='로그아웃 하시나요?'
          description='다음 로그인 시에 자동 로그인이 해제 됩니다.'
          leftButtonText='취소'
          rightButtonText='확인'
          show={logoutPopup}
          onLeftClick={onLClickedLogOutLeft}
          onRightClick={onLClickedLogOutRight}
        />
      }

    </ProfileContainer>
  );
};

export default Profile;
