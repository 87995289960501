import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';

import InfoStep1 from './Info.step1';
import InfoStep2 from './Info.step2';
import InfoStep3 from './Info.step3';
import AppBar1 from 'components/appBar/AppBar1';
import { RegisterUserData } from 'services/BogleUserApi';

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;;
  position: absolute;
  padding: 0em;
  width: 90%;
  height: auto;
  margin-left: 5%;
  top: 7.938rem;
  align-items: flex-start;
  gap: 3rem;
`;

export const InputBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0rem;
  gap: 1rem;
  width: 100%;
`

export interface InfoStepProps {
  setAgreeStep: React.Dispatch<React.SetStateAction<number>>;
  registerUserData: React.MutableRefObject<RegisterUserData>;
}

interface InfoProps {
  registerUserData: React.MutableRefObject<RegisterUserData>;
}

const Info: React.FC<InfoProps> = ({ registerUserData }) => {
  const [agreeStep, setAgreeStep] = useState(1);

  const navigate = useNavigate();

  useEffect(() => {
    if(registerUserData.current.requireTerm == false) {
      navigate("/signup");
    }
    else if(agreeStep == 4) {
      navigate("/login");
    }
  }, [agreeStep]);

  const btnBackClick = () => {
    if (agreeStep == 1) {
      navigate(-1);
    }
    else {
      setAgreeStep(agreeStep - 1);
    }
  };

  return (
    <>
      {/* 상단 */}
      <AppBar1 backAction={btnBackClick} actionString={`${agreeStep}/3`} />

      {/* 메인 */}
      {agreeStep === 1 && 
        <InfoStep1 
          setAgreeStep={setAgreeStep}
          registerUserData={registerUserData} 
        />
      }

      {agreeStep === 2 && 
        <InfoStep2 
          setAgreeStep={setAgreeStep} 
          registerUserData={registerUserData} 
        />
      }

      {agreeStep === 3 && 
        <InfoStep3 
          setAgreeStep={setAgreeStep} 
          registerUserData={registerUserData} 
        />
      }
    </>
  );
};

export default Info;
