import React, { useEffect, useState } from "react";
import styled from "styled-components";
import BogleText from "components/common/BogleText";
import { DateState } from "pages/review/Review";

const DateWapper = styled.div`
    display: flex;
`;

interface DatePickerProps {
    date: DateState;
    setDate: React.Dispatch<React.SetStateAction<DateState>>;
    onClickedDate?: () => void;
}

const DatePicker: React.FC<DatePickerProps> = ({ date, setDate, onClickedDate }) => {
    const [year, setYear] = useState(date.year);
    const [month, setMonth] = useState(date.month);

    const [pickerDate, setPickerDate] = useState('');

    useEffect(() => {
        setYear(date.year);
        setMonth(date.month);

        if(date.month === 0) {
            setPickerDate(`${year}.`);
        }
        else {
            setPickerDate(`${year}.${month}.`);
        }

    }, [date]);

    useEffect(() => {
        setDate({year: year, month: month});
    }, [year, month]);

    const onClickedLeft = () => {
        const pre_month = month- 1;

        if (pre_month < 0) {
            setYear(year - 1);
            setMonth(12);
        } else {
            setMonth(month - 1);
        }
    };

    const onClickedRight = () => {
        const next_month = Number(month) + 1;

        if (year === new Date().getFullYear() && next_month > new Date().getMonth() + 1) return;

        if (next_month > 12) {
            setYear(year + 1);
            setMonth(0);
        } else {
            setMonth(month + 1);
        }
    };

    return (
        <>
            <DateWapper>
                <img onClick={onClickedLeft} src='images/calendar_chevron-left.svg' />
                <div onClick={onClickedDate}>
                    <BogleText color="#32373E" fontSize="1.063rem" style={{ margin: '0 1rem' }}>{pickerDate}</BogleText>
                </div>
                <img onClick={onClickedRight} style={{ transform: 'rotate(180deg)' }} src='images/calendar_chevron-left.svg' />
            </DateWapper>
            <img style={{ display: 'flex', position: 'absolute', right: '0', marginRight: '1.938rem' }} src='images/calendar-06.png' />
        </>
    );
};

export default DatePicker;
