import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import styled from 'styled-components';

import AppBar1 from 'components/appBar/AppBar1';

const NoticeContainer = styled.div`
    display:flex;
    flex-direction: column;
    width: 100%;
`;

const Title = styled.div`
    display:flex;
    padding: 32px 24px 0px; 24px;;

    // text
    font-family: 'Pretendard JP';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    display: flex;
    align-items: center;
    color: #2A2C32;
`;

const Date = styled.div`
    display:flex;
    padding: 0px 24px 20px 24px;
    hegith: 30px;

    // text
    font-family: 'Pretendard JP';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 26px;
    display: flex;
    align-items: center;
    color: #777E88;
`;

const Content = styled.div`
    display:flex;
    padding: 0px 24px;

    // text 
    font-family: 'Pretendard JP';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: #777E88;
`;

interface NoticeData {
    id: number;
    date: string;
    title: string;
    content: string;
}

const notices: NoticeData[] = [
    {
        id: 0,
        date: '2024.04.01',
        title: '공지사항 제목',
        content: '내용입니다. 내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.'
    },
    {
        id: 1,
        date: '2024.04.02',
        title: '공지사항 제목2',
        content: '내용입니다. 내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.'
    },
    {
        id: 2,
        date: '2024.04.03',
        title: '공지사항 제목3',
        content: '내용입니다. 내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.'
    },
    {
        id: 3,
        date: '2024.04.04',
        title: '공지사항 제목4',
        content: '내용입니다. 내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.'
    },
];


const NoticeDetail: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const noticeId = parseInt(id || '0', 10);
    const notice = notices.find((n) => n.id === noticeId);

    const navigate = useNavigate();

    const btnBackClick = () => {
        navigate(-1);
    };

    if (!notice) {
        return null;
    }

    return (
        <NoticeContainer>
            <AppBar1 backAction={btnBackClick}/>

            <Title>{notice.title}</Title>
            <Date>{notice.date}</Date>
            <Content>{notice.content}</Content>
        </NoticeContainer>
    );
};

export default NoticeDetail;
