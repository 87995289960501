import React from 'react';
import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';

import AppBar1 from 'components/appBar/AppBar1';

const NoticeContainer = styled.div`
  width: 100%;
  height: auto;
  min-height: calc(100% - 6.375rem);
  position: relative;
  padding-bottom: 6.375rem;
`;

const NoticeList = styled.div`
  display: flex;
  flex-direction: column;
  margin: 18px 24px;
  
  gap: 28px;
`;

const QuestionWappaer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 0px;
  width: 100%;
  gap: 4px;
`;

const Question = styled.div`
  // text
  font-family: 'Pretendard JP';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #32373E;
`;

const Answer = styled.div`
  padding: 0px 12px;

  // text
  font-family: 'Pretendard JP';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #777E88;
`;

interface NoticeData {
  id: number;
  question: string;
  answer: string;
}

const questions: NoticeData[] = [
  {
    id: 0,
    question: '보드게임 검색할 때 영어로 검색은 불가능 한가요?',
    answer: '답변에 대한 내용 답변에 대한 내용 답변에 대한 내용 답변에 대한 내용'
  },
  {
    id: 1,
    question: '보드게임 검색할 때 영어로 검색은 불가능 한가요?',
    answer: '답변에 대한 내용 답변에 대한 내용 답변에 대한 내용 답변에 대한 내용'
  },
  {
    id: 2,
    question: '보드게임 검색할 때 영어로 검색은 불가능 한가요?',
    answer: '답변에 대한 내용 답변에 대한 내용 답변에 대한 내용 답변에 대한 내용'
  },
  {
    id: 3,
    question: '보드게임 검색할 때 영어로 검색은 불가능 한가요?',
    answer: '답변에 대한 내용 답변에 대한 내용 답변에 대한 내용 답변에 대한 내용'
  },
];

const FAQ: React.FC = () => {
  const navigate = useNavigate();

  const btnBackClick = () => {
    navigate(-1);
  };

  return (
    <NoticeContainer>
      <AppBar1 title='자주 묻는 질문' backAction={btnBackClick}/>

      <NoticeList>
        {questions.map((question) => (
          <QuestionWappaer key={question.id}>
            <Question>Q.{question.question}</Question>
            <Answer>{question.answer}</Answer>
          </QuestionWappaer>
        ))}

      </NoticeList>
    </NoticeContainer>
  );
};

export default FAQ;
