import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

import styled from 'styled-components';

import BogleText from './BogleText';

const NavigationContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0.75rem 1.5rem 2.875rem;
  gap: 2rem;
  position: fixed;
  width: 100%;
  height: 6.375rem;
  left: 0;
  bottom: 0;
  background: #ffffff;
  border-top: 0.03125rem solid #D5D9DD;
`;

const NavigationItem = styled.div<{ $isActive: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  gap: 0.25rem;
  width: 3rem;
  height: 2.75rem;
  cursor: pointer;
  color: ${({ $isActive }) => ($isActive ? '#5F3D9D' : '#A9B1BC')};

  svg path {
    fill: ${({ $isActive }) => ($isActive ? '#A57BDB' : '#A9B1BC')};
  }
`;

const NavigationImage = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const navItems = [
  { path: '/home', text: '홈', svgSrc: '/images/navbar/fi-sr-asterik.svg' },
  { path: '/review', text: '기록', svgSrc: '/images/navbar/fi-sr-pencil.svg' },
  { path: '/game', text: '게임', svgSrc: '/images/navbar/fi-sr-gamepad.svg' },
  { path: '/profile', text: '내정보', svgSrc: '/images/navbar/fi-sr-user.svg' },
];

const BogleNavigation: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <NavigationContainer>
      {navItems.map(item => (
        <NavigationItem
          key={item.path}
          onClick={() => navigate(item.path)}
          $isActive={location.pathname.startsWith(item.path)}
        >
          <NavigationImage>
            <ReactSVG src={item.svgSrc} />
          </NavigationImage>
          <BogleText fontSize="0.625rem" color={location.pathname.startsWith(item.path) ? '#5F3D9D' : '#A9B1BC'}>
            {item.text}
          </BogleText>
        </NavigationItem>
      ))}
    </NavigationContainer>
  );
};

export default BogleNavigation;
