import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

import styled from 'styled-components';

const IndexpageContainer = styled.div`
    width: 100%;
    height: 100%;
    background: #A57BDB;
`;

const ImgWapper = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    svg path {
        fill: #FFFFFF;
    }
`;

const MIN_LOADING_TIME = 1000;

const IndexPage: React.FC = () => {
    const navigate = useNavigate();

    const checkAuthStatus = async () => {
        try {
            const token = localStorage.getItem('accessToken');
    
            await new Promise<void>(resolve => {
                setTimeout(resolve, MIN_LOADING_TIME);
            });

            if (token) {
                navigate('/home');
            } else {
                navigate('/login');
            }
        } catch (error) {
            console.error('Authentication check failed:', error);
        }
    };

    useEffect(() => {
        checkAuthStatus();
    }, []);

    return (
        <IndexpageContainer>
            <ImgWapper>
                <ReactSVG
                    src="/images/logo/boogle-logo.svg"
                    style={{ marginBottom: '0.875rem' }}
                />
                <ReactSVG 
                    src="/images/logo/board-game-community-text.svg" />
            </ImgWapper>
        </IndexpageContainer>
    );
}

export default IndexPage;
