import React from 'react';
import styled from 'styled-components';

import BogleText from './BogleText';

const StyledBogleLogo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center; 
  position: relative;
`;


const StyledInfo = styled.div`
  position: absolute;
  top: -61px;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const StyledImage = styled.img`
  border-radius: 50%;
  border: 4px solid transparent;
`;

interface BogleLogoProps {
  isHighlight: boolean;
  src: string;
  alt: string;
}


const BogleLogo: React.FC<BogleLogoProps> = ({ isHighlight, src, alt }) => {

  return (
    <StyledBogleLogo>
      {isHighlight && (
        <StyledInfo>
          <img src="/images/locked-image.png" style={{ marginBottom: 4 }}/>
          <BogleText fontSize='0.75rem' width="79" height="14" color="#8059BC">{"최근 로그인 계정"}</BogleText>
          <img src="/images/polygon-image.png" style={{ marginTop: 12 }}/>
        </StyledInfo>
      )}
      <StyledImage src={src} alt={alt} style={{ borderColor: isHighlight ? "#8059BC" : "transparent" }} />
    </StyledBogleLogo>
  );
};

export default BogleLogo;
