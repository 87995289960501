import React, { useState } from 'react';
import styled from 'styled-components';

interface SearchBarProps {
  width?: string;
  $background?: string;
  placeholder?: string;
  onSearchClick?: () => void;
}

const SearchBarContainer = styled.div<{ $background?: string; width?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: ${({ width }) => width};
  height: 2.5rem;
  background: ${({ $background }) => $background || '#F6F8F9'};
  border-radius: 999rem;
  position: relative;
`;

const SearchInput = styled.input<{ $background?: string; width?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: ${({ width }) => width};
  height: 100%;
  background: ${({ $background }) => $background || '#F6F8F9'};
  border-radius: 999rem;
  position: relative;
  border: none;

  &::placeholder {
    color: #a9b1bc;
    fontweight: 400;
  }
`;

const CrossImg = styled.img`
  display: flex;
  position: absolute;
  margin-right: 1.5rem;
  right: 0;
`;

const SearchBar: React.FC<SearchBarProps> = ({
  width,
  $background,
  placeholder,
  onSearchClick,
}) => {
  const [inputValue, setInputValue] = useState('');

  return (
    <SearchBarContainer onClick={onSearchClick} width={width}>
      <SearchInput
        width="100%"
        placeholder={placeholder}
        $background={$background}
        style={{ padding: '0rem 3.5rem 0 1.25rem' }}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
      />
      {inputValue && (
        <CrossImg
          src="/images/fi-sr-cross-circle.png"
          onClick={() => setInputValue('')}
        />
      )}
    </SearchBarContainer>
  );
};

export default SearchBar;
