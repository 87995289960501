import React from 'react';
import styled from 'styled-components';

import { ReivewData } from 'services/BogleUserApi';
import { ReactSVG } from 'react-svg';

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  gap: 0.75rem;
  width: 100%;
  max-width: 21.375rem;
  height: 4rem;
`;

const UserInfoWapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 10px;
`;

const UserInfoNameRatingWapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const UserInfoName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 6px;
  height: 16px;
  font-family: 'Pretendard JP';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #32373E;
`;

const UserInfoRating = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 6px;
  width: 84px;
  height: 12px;
`;

const Image = styled.div<{ $imageUrl: string }>`
  width: 38px;
  height: 38px;
  background: url(${props => props.$imageUrl}) no-repeat center center;
  border-radius: 999px;
`;

const UerReviewWapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px 4px;
  gap: 8px;
  font-family: 'Pretendard JP';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-align: justify;
  color: #777E88;
`;

const StarWrapper = styled.div<{ $fillPercentage: number }>`
  width: 12px;
  height: 12px;

  svg {
    width: 100%;
    height: 100%;
  }

  svg path {
    fill: ${({ $fillPercentage }) => {
        if ($fillPercentage === 1) {
            return '#A57BDB';
        } else if ($fillPercentage === 0.5) {
            return ''; 
        } else {
            return '#EEEEEE';
        }
    }};
  }
`;

interface BoardGameList1Props {
  reviewData: ReivewData;
  onClikedItem?: (review: ReivewData) => void;
}

const BoardGameList_UserReview: React.FC<BoardGameList1Props> = ({ reviewData, onClikedItem }) => {

  const renderStars = (rating: string) => {
    const stars = [];
    for (let i = 0; i < 5; i++) {
        const fillPercentage = Math.max(0, Math.min(1, Number(rating) - i));
        
        stars.push(
            <StarWrapper key={i} $fillPercentage={fillPercentage}>
                <ReactSVG src='/images/review/fi-sr-star.svg' />
            </StarWrapper>
        );
    }
    return stars;
};

  return (
    <ItemWrapper onClick={() => onClikedItem && onClikedItem(reviewData)}>

      <UserInfoWapper>
        <Image $imageUrl={reviewData.imageUrl} />

        <UserInfoNameRatingWapper>
          <UserInfoName>유저 닉네임</UserInfoName>

          <UserInfoRating>
            {renderStars(reviewData.rating)}
          </UserInfoRating>
        </UserInfoNameRatingWapper>
      </UserInfoWapper>

      <UerReviewWapper>
        {reviewData.review}
      </UerReviewWapper>
    </ItemWrapper>
  );
};

export default BoardGameList_UserReview;
