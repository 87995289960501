import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import styled from "styled-components";

import BoardGameList_Horizontal from "components/boardGameList/BaordGameList.Horizontal";
import BogleNavigation from "components/common/BogleNavigation";
import BogleText from "components/common/BogleText";
import DatePicker from "components/date/DatePicker";
import { Header2 } from "components/common/Header";
import { ReivewData } from "services/BogleUserApi";
import SortBottomSheet from "./BottomSheet/SortBottomSheet";
import BoardGameBottomSheet from "./BottomSheet/BoardGameBottomSheet";
import DatePickerBottomSheet from "./BottomSheet/DatePickerBottomSheet";

const ReviewContainer = styled.div`
    width: 100%;
    height: auto;
    min-height: calc(100% - 6.375rem);
    position: relative;
    padding-bottom: 6.375rem;
`;

const DatePickerWapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 2rem 0;
`;

const ReviewListTopWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 1.5rem;
    gap: 0.5rem;
`;

const ReviewListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1.5rem;
    gap: 1.5rem;
`;

const FloatingButtonWapper = styled.div`
    position: fixed;
    right: 1.5rem;
    bottom: 8.688rem;
    filter: drop-shadow(0px 3px 16px rgba(0, 0, 0, 0.25));
`;

export const SORT_CRITERIA = {
    LATEST: '최신 등록 순',
    RATING: '별점 높은 순',
    ALPHABETICAL: '가나다 순'
};

export interface DateState {
    year: number;
    month: number;
}

const Review = () => {
    const [sortBottomSheetOpen, setSortBottomSheetOpen] = useState(false);
    const [sort, setSort] = useState(SORT_CRITERIA.LATEST);

    const [datetBottomSheetOpen, setDateBottomSheetOpen] = useState(false);
    const [date, setDate] = useState<DateState>({ year: new Date().getFullYear(), month: new Date().getMonth() + 1 });

    const [boardGametBottomSheetOpen, setBoardGameBottomSheetOpen] = useState(false);
    const [reviewData, setReviewData]= useState<ReivewData | null>(null);

    const navigate = useNavigate();

    const reviews: ReivewData[] = [
        {
            imageUrl: '/images/BoardGameListImg.png',
            name: '보드게임 이름1',
            rating: '4.0',
            review: '이 게임은 너무 재밌네요 정말 재밌어서 시간가는 줄 몰랐답니다 하하하하하하하하하이 게임은 너무 재밌네요 정말 재밌어서 시간가는 줄 몰랐답니다 하하하하하하하하하이 게임은 너무 재밌네요 정말 재밌어서 시간가는 줄 몰랐답니다 하하하하하하하하하',
            date: '2022.3.10',
            tags: []
        },
        {
            imageUrl: '/images/BoardGameListImg.png',
            name: '보드게임 이름2',
            rating: '4.5',
            review: '보드게임 후기 한줄',
            date: '2010.3.5',
            tags: []
        },
        {
            imageUrl: '/images/BoardGameListImg.png',
            name: '보드게임 이름3',
            rating: '4.0',
            review: '보드게임 후기 한줄',
            date: '2024.7.4',
            tags: []
        },
        {
            imageUrl: '/images/BoardGameListImg.png',
            name: '보드게임 이름3',
            rating: '3.5',
            review: '보드게임 후기 한줄',
            date: '2024.3.8',
            tags: []
        },
        {
            imageUrl: '/images/BoardGameListImg.png',
            name: '보드게임 이름3',
            rating: '5.0',
            review: '보드게임 후기 한줄',
            date: '2024.10.3',
            tags: []
        },
        {
            imageUrl: '/images/BoardGameListImg.png',
            name: '보드게임 이름3',
            rating: '1.5',
            review: '보드게임 후기 한줄',
            date: '2014.3.3',
            tags: []
        }
    ];

    const onClickedReivewListTop = () => {
        setSortBottomSheetOpen(true);
        setSortBottomSheetOpen(true);
    };

    const onClickedFloatingButton = () => {
        navigate('search');
    };

    const onClickedDate = () => {
        setDateBottomSheetOpen(true);
    };

    const onClickedBoardgameList = (reviewData: ReivewData) => {
        setBoardGameBottomSheetOpen(true);
        setReviewData(reviewData);
    };
    
    return (
        <ReviewContainer>
            <Header2 />
            <DatePickerWapper>
                <DatePicker date={date} setDate={setDate} onClickedDate={onClickedDate}/>
            </DatePickerWapper>
            <ReviewListTopWrapper onClick={onClickedReivewListTop}>
                <img src='/images/arrow-down-contained-01.svg'/>
                <BogleText color="#32373E">{sort}</BogleText>
            </ReviewListTopWrapper>
            <ReviewListWrapper>
                {reviews.map((review, index) => (
                    <BoardGameList_Horizontal
                        key={index}
                        reviewData={review}
                        onClikedItem={() => onClickedBoardgameList(review)}
                    />
                ))}
            </ReviewListWrapper>
    
            <BogleNavigation />
            
            <FloatingButtonWapper onClick={onClickedFloatingButton}>
                <img src='images/review/ic_fab1.svg'/>
            </FloatingButtonWapper>

            <SortBottomSheet 
                isBottomSheetOpen={sortBottomSheetOpen}
                setIsBottomSheetOpen={setSortBottomSheetOpen}
                sort={sort} 
                setSort={setSort}  
            />
            
            <DatePickerBottomSheet 
                isBottomSheetOpen={datetBottomSheetOpen}
                setIsBottomSheetOpen={setDateBottomSheetOpen}
                date={date} 
                setDate={setDate}  
            />

            <BoardGameBottomSheet 
                isBottomSheetOpen={boardGametBottomSheetOpen}
                setIsBottomSheetOpen={setBoardGameBottomSheetOpen}
                reviewData={reviewData} 
            />

        </ReviewContainer>
    );
};

export default Review;

