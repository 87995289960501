import React, { useState } from "react";
import { ReactSVG } from "react-svg";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import AppBar1 from "components/appBar/AppBar1";
import BogleButton from "components/common/BogleButton";
import BogleText from "components/common/BogleText";
import { ReivewData } from "services/BogleUserApi";
import { useStarRating } from "hooks/UseStarRacting";

const GameInfoWapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 1.5rem;
    gap: 0.5rem;
`;

const GameInfoImg = styled.img`
    width: 62%;
`;

const GameInfoRating = styled.div`
    display: flex;
    gap: 0.75rem;
    justify-content: center;
    position: relative;
`;

const StarWrapper = styled.div<{ $fillPercentage: number }>`
    svg path {
        fill: ${({ $fillPercentage }) => {
            if ($fillPercentage === 1) {
                return '#A57BDB';
            } else if ($fillPercentage === 0.5) {
                return ''; 
            } else {
                return '#EEEEEE';
            }
        }};
    }
`;

const GameReviewWapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 1.5rem;
`;

const GameReviewTitle = styled.div`
    display: flex;
    gap: 0.5rem;
`;

const GameReviewTextField = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const GameReviewinput = styled.textarea`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 1rem 1.25rem 4rem 1.25rem;
    background: #F6F8F9;
    border-radius: 0.75rem;
    border: none;
    overflow: hidden;

    font-family: 'Pretendard JP';
    font-size: 1.063rem;
    line-height: 1.25rem;
    color: #333333;

     &::placeholder {
        display: flex;
        font-family: 'Pretendard JP';
        font-style: normal;
        font-weight: 400;
        font-size: 1.063rem;
        line-height: 1.25rem;
        align-items: center;
        color: #A9B1BC;
    }
`;

const GameRviewTextLength = styled.div`
    display: flex;
    padding: 0 0.5rem;
    font-family: 'Pretendard JP';
    font-style: normal;
    font-weight: 400;
    font-size: 0.625rem;
    line-height: 1rem;
    text-align: right;
    color: #A9B1BC;
    justify-content: flex-end;
`;

const maxLength = 300;

const Write: React.FC = () => {
    const [review, setReview] = useState("");
    const reviewData = useLocation().state as ReivewData;
    const { rating, ratingWrapperRef, handleDragStart, handleDragEnd } = useStarRating(Number(reviewData.rating) || 0);
    const navigate = useNavigate();

    const btnBackClick = () => {
        navigate(-1);
    };

    const onClickedSaveReview = () => {
        reviewData.review = review;
        reviewData.rating = rating.toString();
    };

    const renderStars = (rating: number) => {
        const stars = [];
        for (let i = 0; i < 5; i++) {
            const fillPercentage = Math.max(0, Math.min(1, rating - i));
            stars.push(
                <StarWrapper key={i} $fillPercentage={fillPercentage}>
                    <ReactSVG src='/images/review/fi-sr-star.svg' />
                </StarWrapper>
            );
        }
        return stars;
    };

    const onChangeInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const inputValue = e.target.value;
        if (inputValue.length > 300) return;
        setReview(inputValue);
    };

    return (
        <>
            <AppBar1 backAction={btnBackClick} />
            <GameInfoWapper>
                <GameInfoImg src='/images/review/game-image.png' />
                <BogleText color='#2A2C32' fontSize='1.063rem' style={{ padding: '0.688rem' }}>{reviewData.name}</BogleText>
                <GameInfoRating
                    ref={ratingWrapperRef}
                    onMouseDown={handleDragStart}
                    onTouchStart={handleDragStart}
                    onMouseUp={handleDragEnd}
                    onTouchEnd={handleDragEnd}
                    onTouchCancel={handleDragEnd}
                >
                    {renderStars(rating)}
                </GameInfoRating>
            </GameInfoWapper>
            <GameReviewWapper>
                <GameReviewTitle>
                    <img src='/images/review/memo.svg' />
                    <BogleText color='#32373E' fontSize='0.938rem'>게임 후기</BogleText>
                </GameReviewTitle>
                <GameReviewTextField>
                    <GameReviewinput value={review} onChange={onChangeInput} placeholder='게임 후기를 작성해 주세요' />
                    <GameRviewTextLength>{`${review.length}/${maxLength}`}</GameRviewTextLength>
                </GameReviewTextField>
            </GameReviewWapper>
            <BogleButton
                width="90%"
                height="3.5rem"
                $background="#A57BDB"
                radius="1rem"
                style={{ position: 'absolute', left: '5%', bottom: '0', justifyContent: 'center', margin: '2rem 0rem 1.563rem' }}
                onClick={onClickedSaveReview}
            >
                <BogleText fontSize="1.063rem">저장</BogleText>
            </BogleButton>
        </>
    );
};

export default Write;
