import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Header2 } from 'components/common/Header';
import BogleNavigation from 'components/common/BogleNavigation';
import GameTheme from 'components/game/GameTheme';
import GameCategory from 'components/game/GameCategory';
import { ReivewData } from 'services/BogleUserApi';
import BoardGameList_InTitleNum from 'components/boardGameList/BoardGameList.InTitleNum';

const GameContainer = styled.div`
  width: 100%;
  height: auto;
  min-height: calc(100% - 6.375rem);
  position: relative;
  padding-bottom: 6.375rem;
`;

const GanmeThemeWapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 220px;
  background: #FFFFFF;
  flex-wrap: wrap;
  padding: 16px 13px 24px 13px;
  overflow: auto;
  scrollbar-width: none;
`;

const GanmeCategoryWapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #FFFFFF;
  padding: 24px;
`;

const GanmeHotListWapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1.5rem;
  gap: 2rem;
`;

const SubTitleText = styled.div`
  font-family: 'Pretendard JP';
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: #32373E;
`;

const reviews: ReivewData[] = [
  {
    imageUrl: '/images/BoardGameListImg.png',
    name: '보드게임 이름1',
    rating: '4.0',
    review: '보드게임 후기 한줄로 작성합니다 이렇게하면 두줄로 보여집니다',
    date: '2024.3.3',
    tags: ['전략', '파티'],
  },
  {
    imageUrl: '/images/BoardGameListImg.png',
    name: '보드게임 이름2',
    rating: '4.5',
    review: '후기 내용2',
    date: '2024.3.3',
    tags: ['전략'],
  },
  {
    imageUrl: '/images/BoardGameListImg.png',
    name: '보드게임 이름3',
    rating: '4.1',
    review: '보드게임 후기 한줄로 작성합니다 이렇게하면 두줄로 보여집니다',
    date: '2024.3.3',
    tags: ['전략'],
  },
  {
    imageUrl: '/images/BoardGameListImg.png',
    name: '보드게임 이름3',
    rating: '4.1',
    review: '보드게임 후기 한줄로 작성합니다 이렇게하면 두줄로 보여집니다',
    date: '2024.3.3',
    tags: ['전략'],
  },
  {
    imageUrl: '/images/BoardGameListImg.png',
    name: '보드게임 이름3',
    rating: '4.1',
    review: '보드게임 후기 한줄로 작성합니다 이렇게하면 두줄로 보여집니다',
    date: '2024.3.3',
    tags: ['전략'],
  },
];

const Game: React.FC = () => {

  const navigate = useNavigate();
  
  const onClickedGameList = (review: ReivewData) => {
    navigate('/game/info', { state: review });
  };

  return (
    <GameContainer>
      <Header2 />

      <GanmeThemeWapper>
          <GameTheme />
      </GanmeThemeWapper>

      <GanmeCategoryWapper>
        <SubTitleText>
          카테고리
        </SubTitleText>
        <GameCategory />
      </GanmeCategoryWapper>

      <GanmeHotListWapper>
        <SubTitleText>
          요즘 핫 한 게임
        </SubTitleText>
          {reviews.map((review, index) => (
            <BoardGameList_InTitleNum
              key={index}
              titleNum={index + 1}
              playTime={213}
              reviewData={review}
              onClikedItem={() => {onClickedGameList(review)}}
            />
          ))}
      </GanmeHotListWapper>

      <BogleNavigation />

    </GameContainer>
  );
};

export default Game;
