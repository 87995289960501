import React from 'react';
import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';

import BogleButton from 'components/common/BogleButton';
import AppBar1 from 'components/appBar/AppBar1';

const NoticeContainer = styled.div`
  width: 100%;
  height: auto;
  min-height: calc(100% - 6.375rem);
  position: relative;
  padding-bottom: 6.375rem;
`;

const NoticeList = styled.div`
  display: flex;
  flex-direction: column;
  margin: 18px 24px;
`;

const StyledChecvronRight = styled.div`
  position: absolute;
  width: 72px;

  display: flex;
  justify-content: center;
  align-items: center;
  right: 0;
`;

const NoticeInfoWaper = styled.div`
  display: flex;
  flex-direction: column;
`;

const NoticeDateText = styled.div`
  font-family: 'Pretendard JP';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #777E88;
`;

const NoticeTitleText = styled.div`
  font-family: 'Pretendard JP';
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: #32373E;
`;

const ContourLine = styled.div`
  disflay: flex;
  background: #D5D9DD;
  height: 0.5px;
`;

interface NoticeData {
  id: number;
  date: string;
  title: string;
  content: string;
}

const notices: NoticeData[] = [
  {
    id: 0,
    date: '2024.04.01',
    title: '공지사항 제목',
    content: '내용입니다. 내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.'
  },
  {
    id: 1,
    date: '2024.04.02',
    title: '공지사항 제목2',
    content: '내용입니다. 내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.'
  },
  {
    id: 2,
    date: '2024.04.03',
    title: '공지사항 제목3',
    content: '내용입니다. 내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.'
  },
  {
    id: 3,
    date: '2024.04.04',
    title: '공지사항 제목4',
    content: '내용입니다. 내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.내용입니다.'
  },
];

const Notice: React.FC = () => {
  const navigate = useNavigate();

  const btnBackClick = () => {
    navigate(-1);
  };

  const onClickedNotice = (id: number) => {
    navigate(`/profile/notice/${id}`);
  };

  return (
    <NoticeContainer>
      <AppBar1 title='공지사항' backAction={btnBackClick}/>

      <NoticeList>
        {notices.map((notice) => (
          <React.Fragment key={notice.id}>
            <BogleButton
              height="56px"
              $applyAnimation={true}
              style={{ 
                padding: '0px 48px 0px 0px',
                margin: '18px 0px',
              }}
              onClick={() => onClickedNotice(notice.id)}
            >
              <StyledChecvronRight>
                <img src="/images/chevron-right.svg" alt="chevron-right" />
              </StyledChecvronRight>

              <NoticeInfoWaper>
                <NoticeDateText>{notice.date}</NoticeDateText>
                <NoticeTitleText>{notice.title}</NoticeTitleText>
              </NoticeInfoWaper>
            </BogleButton>

            <ContourLine />
          </React.Fragment>
        ))}

      </NoticeList>
    </NoticeContainer>
  );
};

export default Notice;
