import React from 'react';
import styled from 'styled-components';

const HeaderContainer = styled.header<{$background: string}>`
  display: flex;
  position: relative;
  width: 100%;
  height: 5.9375rem; 
  justify-content: center;
  top: 0;
  background: ${({ $background }) => $background}};
`;

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 60%;
  width: 90%;
  height: 3rem; 
  position: absolute;
  bottom: 0;
`;

const ComponentContainer = styled.div`
  display: flex;
  position: absolute;
  left: 0;
  align-items: center;
  gap: 0.375rem; 
  height: 1.125rem; 
  margin: 0 auto;
`;

const BogleText = styled.div<{color: string}>`
  font-style: normal;
  font-weight: 300;
  font-size: 1rem; 
  line-height: 1.25rem;
  color: ${({color: textColor}) => textColor};
`;

const NotificationContainer = styled.div`
  display: flex;
  position: absolute;
  right: 0;
  justify-content: flex-end;
  align-items: center;
  gap: 1.25rem; 
  width: 1.5rem; 
  height: 1.5rem; 
  margin: 0 auto;
`;

export const Header1: React.FC = () => {
  return (
    <>
      <HeaderContainer $background='#A57BDB'>
        <HeaderContent>
          <ComponentContainer>
            <img src="/images/bogle1.png" />
            <BogleText color='#FFFFFF'>보글</BogleText>
          </ComponentContainer>
          <NotificationContainer>
            <img src="/images/bell-02.png" />
          </NotificationContainer>
        </HeaderContent>
      </HeaderContainer>
    </>
  );
};

export const Header2: React.FC = () => {
  return (
    <>
      <HeaderContainer $background='#FFFFFF'>
        <HeaderContent>
          <ComponentContainer>
            <img src="/images/bogle2.png" />
            <BogleText color='#32373E'>보글</BogleText>
          </ComponentContainer>
          <NotificationContainer>
            <img src="/images/bell-02.png" />
          </NotificationContainer>
        </HeaderContent>
      </HeaderContainer>
    </>
  );
};