import React from 'react';

import styled from 'styled-components';

import BogleButton from 'components/common/BogleButton';
import BogleText from 'components/common/BogleText';

const HeaderContainer = styled.div`
    position: relative;
    width: 100%;
    height: 5.938rem;
    text-align: center;
`;

interface AppBar1Props {
    title?: string;
    actionString?: string;
    backAction?: () => void;
}

const AppBar1: React.FC<AppBar1Props> = ({title, actionString, backAction}) => {
    return (
        <HeaderContainer>
            <BogleButton onClick={backAction} width='4rem' height='3rem'
                style={{ position: 'absolute', left: '0em', bottom: '0em', justifyContent: 'center' }}>
                <img src='/images/chevron-left.svg' />
            </BogleButton>
            <BogleText
                width='10rem' height='3rem' color='#2A2C32' fontSize='1.063rem'
                style={{ display: 'flex', position: 'absolute', left: '50%', bottom: '0em', transform: 'translateX(-50%)' }}>
                {title}
            </BogleText>
            <BogleText width='3.375rem' height='3rem' color='#32373E' fontSize='0.938rem'
                style={{ display: 'flex', position: 'absolute', top: '49.473%', right: '0rem', border: '0.125rem' }}>
                {actionString}
            </BogleText>
        </HeaderContainer>
    );
};

export default AppBar1;
