import React from "react";
import { useNavigate } from "react-router-dom";

import styled from "styled-components";

const GameThemeWapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 4px;
    gap: 4px;

    width: 83px;
`;

const GameThemeImgWapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;

    width: 64px;
    height: 64px;

    background: #F5F5F5;
    border-radius: 1rem;
`;

const GameThemeText = styled.div`
    font-family: 'Pretendard JP';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;

    display: flex;
    align-items: center;
    text-align: center;

    color: #32373E;
`;

interface gameThemeDataInfo {
    imageUrl: string;
    text: string;
    url: string;
}

const gameThemeData: gameThemeDataInfo[] = [
    {
        imageUrl: '/images/game/emoj-icons-right-loudspeaker.svg',
        text: '신규',
        url: 'new',
    },
    {
        imageUrl: '/images/game/emoj-icons-hatching-chick.svg',
        text: '파티',
        url: 'party',
    },
    {
        imageUrl: '/images/game/emoj-icons-light-bulb.svg',
        text: '전략',
        url: 'strategy',
    },
    {
        imageUrl: '/images/game/emoj-icons-hot-face.svg',
        text: '머더 미스테리',
        url: 'murder-mystery',
    },
    {
        imageUrl: '/images/game/emoj-icons-smiling-face-with-hearts.svg',
        text: '협력',
        url: 'cooperation',
    },
    {
        imageUrl: '/images/game/emoj-icons-video-game.svg',
        text: '경마',
        url: 'racing',
    },
    {
        imageUrl: '/images/game/emoj-icons-pile-of-poo.svg',
        text: '블러핑',
        url: 'bluffing',
    },
    {
        imageUrl: '/images/game/emoj-icons-magnifying-glass-tilted-left.svg',
        text: '카드',
        url: 'card',
    },    
    {
        imageUrl: '/images/game/emoj-icons-love-you-gesture.svg',
        text: '도박',
        url: 'gambling',
    },
    {
        imageUrl: '/images/game/emoj-icons-sparkling-heart.svg',
        text: '추리',
        url: 'reasoning',
    },
];

const GameTheme: React.FC = () => {
    const navigate = useNavigate();

    const onClickedGameTheme = (text: string, url: string) => {
        navigate(`../category/${url}`, { state: text });
    };

    return(
        <>
            {gameThemeData.map((data, index) => (
                <GameThemeWapper key={index}>
                    <GameThemeImgWapper onClick={ () => {onClickedGameTheme(data.text, data.url)}}>
                        <img src={data.imageUrl} />
                    </GameThemeImgWapper>
                    <GameThemeText>{data.text}</GameThemeText>
                </GameThemeWapper>
            ))}
        </>
    );
};


export default GameTheme;