import React from 'react';
import styled from 'styled-components';

const TagWrapper = styled.div<{ background: string }>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 0.375rem;
    gap: 0.5rem;
    height: 1rem;
    background: ${props => props.background};
    border-radius: 999px;
    font-family: 'Pretendard JP', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 0.625rem;
    line-height: 1rem;
    color: #32373E;
`;

interface TagProps {
    tagText: string;
}

const backgroundColorMap: { [key: string]: string } = {
    전략: '#E8DEEE',
    파티: '#FFE2DD',
    크라임씬: '#F1F0EF',
    경매: '#DBEDDB',
};

const Tag: React.FC<TagProps> = ({ tagText }) => {
    const background = backgroundColorMap[tagText] || '#E8DEEE';
    return <TagWrapper background={background}>{tagText}</TagWrapper>;
};

export default Tag;
