import React from 'react';
import styled from 'styled-components';

const StyledToggleContainer = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  min-width: 1.5rem;
`;

const StyledToggleImg = styled.img`
  width: 100%;
  height: 100%;
`;

interface BogleToggleProps {
  isOn: boolean;
  onToggle: () => void;
}

const BogleToggle: React.FC<BogleToggleProps> = ({ isOn, onToggle }) => {
  const onImage = '/images/check-on.svg';
  const offImage = '/images/check-off.svg';

  const handleClick = () => {
    onToggle();
  };

  return (
    <StyledToggleContainer onClick={handleClick}>
      <StyledToggleImg src={isOn ? onImage : offImage} />
    </StyledToggleContainer>
  );
};

export default BogleToggle;
