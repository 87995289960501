import React from 'react';
import styled from 'styled-components';

import { ReivewData } from 'services/BogleUserApi';

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  gap: 0.75rem;
  width: 100%;
  max-width: 21.375rem;
  height: 4rem;
`;

const Image = styled.div<{ $imageUrl: string }>`
  min-width: 3.75rem;
  min-height: 3.75rem;
  background: url(${props => props.$imageUrl}) no-repeat center center;
  background-size: cover;
  border-radius: 0.5rem;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0;
  gap: 0.125rem;
  max-width: 16.875rem;
  height: 4rem;
`;

const NameRatingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  gap: 0.75rem;
  max-width: 16.875rem;
  height: 1.375rem;
`;

const GameName = styled.div`
  width: auto;
  height: 1.375rem;
  font-family: 'Pretendard JP', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  display: flex;
  align-items: center;
  color: #1F2326;
`;

const RatingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  gap: 0.25rem;
  width: 2.4375rem;
  height: 1.375rem;
`;

const Rating = styled.div`
  width: 1.4375rem;
  height: 1.375rem;
  font-family: 'Pretendard JP', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  display: flex;
  align-items: center;
  color: #A57BDB;
`;

const GameData = styled.div`
  font-family: 'Pretendard JP';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #777E88;
`;

const PlayTime = styled.div`
  font-family: 'Pretendard JP';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  color: #777E88;
  padding: 0px 2px 0px 2px;
`;

const TitleNum = styled.div`
  font-family: 'Pretendard JP';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  color: #2C1769;
  padding-right: 12px;
`;

interface BoardGameList1Props {
  reviewData: ReivewData;
  titleNum?: number;
  playTime: number;
  onClikedItem?: (review: ReivewData) => void;
}

const BoardGameList_InTitleNum: React.FC<BoardGameList1Props> = ({ reviewData, titleNum, playTime, onClikedItem }) => {
  return (
    <ItemWrapper onClick={() => onClikedItem && onClikedItem(reviewData)}>
      {titleNum && <TitleNum>{titleNum}</TitleNum>}

      <Image $imageUrl={reviewData.imageUrl} />

      <InfoWrapper>
        <NameRatingWrapper>
          <GameName>{reviewData.name}</GameName>

          <RatingWrapper>
            <img src='/images/fi-sr-star.png'/>
            <Rating>{reviewData.rating}</Rating>
          </RatingWrapper>

        </NameRatingWrapper>

        <GameData>카테고리 ㆍ 난이도 ㆍ 인원 수</GameData>

        {reviewData.tags && reviewData.tags.length > 0 && (
          <PlayTime>{playTime}번 플레이</PlayTime>
        )}

      </InfoWrapper>
    </ItemWrapper>
  );
};

export default BoardGameList_InTitleNum;
