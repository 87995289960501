import React from 'react';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';
import BogleText from 'components/common/BogleText';
import BogleButton from 'components/common/BogleButton';
import BottomSheet from 'components/bottomSheet/BottomSheet';
import { ReivewData } from 'services/BogleUserApi';

const BottomSheetTitleWrapper = styled.div`
    width: 100%;
    font-family: 'Pretendard JP';
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 28px;
    display: flex;
    align-items: center;
    text-align: center;
    padding: 1.5rem 0 0;
    justify-content: center;
    color: #32373E;
`;

const BottomSheetRatingWrapper = styled.div`
    display: flex;
    width: 100%;
    padding: 2rem 0;
    gap: 0.75rem;
    justify-content: center;
`;

const StarWrapper = styled.div<{ $fillPercentage: number }>`
    svg path {
        fill: ${({ $fillPercentage }) => {
            if ($fillPercentage === 1) {
                return '#A57BDB';
            } else if ($fillPercentage === 0.5) {
                return ''; 
            } else {
                return '#EEEEEE';
            }
        }};
    }
`;

const BottomSheetReviewWrapper = styled.div`
    display: flex;
    padding: 0 1.5rem;
    gap: 0.75rem;
    justify-content: center;

    font-family: 'Pretendard JP';
    font-style: normal;
    font-weight: 400;
    font-size: 0.938rem;

    color: #32373E;

    height: 6.875rem;
`;

interface BoardGameBottomSheetProps {
    isBottomSheetOpen: boolean;
    setIsBottomSheetOpen: React.Dispatch<React.SetStateAction<boolean>>;
    reviewData: ReivewData | null;
}

const BoardGameBottomSheet: React.FC<BoardGameBottomSheetProps> = ({ isBottomSheetOpen, setIsBottomSheetOpen, reviewData }) => {
    if (!reviewData) return null;

    const onClickedSetSort = () => {
        setIsBottomSheetOpen(false);
    };

    const renderStars = (rating: string) => {
        const stars = [];
        for (let i = 0; i < 5; i++) {
            const fillPercentage = Math.max(0, Math.min(1, Number(rating) - i));
            
            stars.push(
                <StarWrapper key={i} $fillPercentage={fillPercentage}>
                    <ReactSVG src='/images/review/fi-sr-star.svg' />
                </StarWrapper>
            );
        }
        return stars;
    };

    return (
        <BottomSheet isBottomSheetOpen={isBottomSheetOpen} setIsBottomSheetOpen={setIsBottomSheetOpen}>
            <BottomSheetTitleWrapper>{reviewData.date}</BottomSheetTitleWrapper>

            <BottomSheetRatingWrapper>
                {renderStars(reviewData.rating)}
            </BottomSheetRatingWrapper>

            <BottomSheetReviewWrapper>
                {reviewData.review}
            </BottomSheetReviewWrapper>

            <BogleButton 
                width='90%'
                height='3.5rem'
                $background='#A57BDB'
                radius='1rem'
                style={{ position: 'relative', left: '5%', justifyContent: 'center', margin: '2rem 0 1.563rem'}}
                onClick={onClickedSetSort}>
                <BogleText fontSize='1.063rem'>닫기</BogleText>
            </BogleButton>
        </BottomSheet>
    );
};

export default BoardGameBottomSheet;
