import React from 'react';
import { Route, Routes } from 'react-router-dom';

import styled from 'styled-components';
import Review from './Review';
import Search from 'pages/search/Search';
import Write from '../write/Write';

export const BottomContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  left: 0em;
  bottom: 0em;
`;

const ReviewRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Review />} />
      <Route path="write" element={<Write />} />
      <Route path="search" element={<Search />} />
  </Routes>
  );
};

export default ReviewRoutes;