import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Profile from './Profile';
import Notice from './Notice';
import NoticeDetail from './NoticeDetail';
import FAQ from './FAQ';
import ProfileEdit from './ProfileEdit';

const ProfileRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Profile />} />
      <Route path="/edit" element={<ProfileEdit />} />
      <Route path="/notice" element={<Notice />} />
      <Route path="/notice/:id" element={<NoticeDetail />} />
      <Route path="/faq" element={<FAQ />} />
    </Routes>
  );
};

export default ProfileRoutes;
