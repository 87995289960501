import { useState, useCallback, useRef, useEffect } from 'react';

export const useStarRating = (initialRating = 0) => {
    const [rating, setRating] = useState(initialRating);
    const [isDragging, setIsDragging] = useState(false);
    const ratingWrapperRef = useRef<HTMLDivElement>(null);

    const roundToNearestHalf = (num: number) => {
        return Math.round(num * 2) / 2;
    };
    
    const handleMouseMove = useCallback((e: MouseEvent | TouchEvent) => {
        if (isDragging === false) return;

        if (ratingWrapperRef.current) {
            const clientX = 'touches' in e ? e.touches[0].clientX : e.clientX;
            const { left, width } = ratingWrapperRef.current.getBoundingClientRect();
            const newRating = Math.min(Math.max((clientX - left) / width * 5, 0), 5);
            setRating(roundToNearestHalf(newRating));
        }
    }, [isDragging]);

    const handleDragStart = useCallback(() => {
        setIsDragging(true);
    }, []);

    const handleDragEnd = useCallback(() => {
        setIsDragging(false);
    }, []);

    useEffect(() => {
        if (isDragging) {
            window.addEventListener('mousemove', handleMouseMove);
            window.addEventListener('touchmove', handleMouseMove);
            window.addEventListener('mouseup', handleDragEnd);
            window.addEventListener('touchend', handleDragEnd);
            window.addEventListener('touchcancel', handleDragEnd);
        }

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('touchmove', handleMouseMove);
            window.removeEventListener('mouseup', handleDragEnd);
            window.removeEventListener('touchend', handleDragEnd);
            window.removeEventListener('touchcancel', handleDragEnd);
        };
    });

    return { rating, ratingWrapperRef, handleDragStart, handleDragEnd };
};
