import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import styled, { css } from 'styled-components';

import BogleButton from 'components/common/BogleButton';
import BogleToggle from 'components/common/BogleToogle';
import BogleText from 'components/common/BogleText';
import { InfoFooter } from '.';
import AppBar1 from 'components/appBar/AppBar1';
import { RegisterUserData } from 'services/BogleUserApi';

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  padding: 0em;
  width: 90%;
  height: 45%;
  margin-left: 5%;
  top: 7.938rem;
  gap: 0.5rem;
  align-items: center;
`;

const AgreeContainer = styled.div`
  width: 100%;
  height: 100%;
`

const BodyAgreeContainer = styled.div<{ $withpadding?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0rem;
  gap: 1rem;
  width: 87.7%;
  height: 3.5rem;
  min-width: 10rem;
  ${({ $withpadding: withpadding }) =>
    withpadding &&
    css`
      padding-left: 1.25em;
    `}
`;

interface AgreeTextContainerProps {
  $withpadding: boolean;
  text: string;

  isOn: boolean;
  onToggle: () => void;
}

const AgreeTextContainer: React.FC<AgreeTextContainerProps> = ({ $withpadding, text, isOn, onToggle }) => {
  return (
    <BodyAgreeContainer $withpadding={$withpadding}>
      <BogleToggle isOn={isOn} onToggle={onToggle}/>
      <BogleButton width='90%' height='100%' $ischevronright={true}
        style={{ display: 'flex'}}>
        <BogleText color='#777E88' fontSize='1.063rem' $justifycontent='left' width='66%'>
          {text}
        </BogleText>
      </BogleButton>
    </BodyAgreeContainer>
  )
}

interface AgreeProps {
  registerUserData: React.MutableRefObject<RegisterUserData>;
}

const Agree: React.FC<AgreeProps> = ({registerUserData}) => {
  const [requireTerm, SetRequireTerm] = useState(false);
  const [requireTerm2, SetRequireTerm2] = useState(false);
  const [requireTerm3, SetRequireTerm3] = useState(false);

  const [optionTerm1, SetOptionTerm1] = useState(false);
  const [optionTerm2, SetOptionTerm2] = useState(false);

  const [isAllAgree, SetIsAllAgree] = useState(false);

  const navigate = useNavigate();
  
  const btnBackClick = () => {
    navigate('/login');
  };
  
  const btnNextClick = () => {
    if (requireTerm && requireTerm2 && requireTerm3) {
      registerUserData.current.requireTerm = true;
      
      registerUserData.current.optionTerm1 = optionTerm1;
      registerUserData.current.optionTerm2 = optionTerm2;

      navigate("/signup/info");
    }
  };
  
  const btnRegisterUserDataClick = () => {
    SetRequireTerm(!isAllAgree);
    SetRequireTerm2(!isAllAgree);
    SetRequireTerm3(!isAllAgree);
    SetOptionTerm1(!isAllAgree);
    SetOptionTerm2(!isAllAgree);

    SetIsAllAgree(!isAllAgree);
  };
  
  return (
    <AgreeContainer>

      {/* 상단 */}
      <AppBar1 title='회원가입' backAction={btnBackClick} />
    
      {/* 메인 */}
      <BodyContainer>
        <AgreeTextContainer
          $withpadding={false}
          text='전체 동의'
          isOn={(
            requireTerm &&
            requireTerm2 &&
            requireTerm3 &&
            optionTerm1 &&
            optionTerm2
          )}
          onToggle={() => btnRegisterUserDataClick()}
        />

        <AgreeTextContainer
          $withpadding={true}
          text='(필수) 이용약관'
          isOn={requireTerm}
          onToggle={() => SetRequireTerm(!requireTerm)}
        />
        <AgreeTextContainer
          $withpadding={true}
          text='(필수) 개인정보 수집 및 이용 안내'
          isOn={requireTerm2}
          onToggle={() => SetRequireTerm2(!requireTerm2)}
        />
        <AgreeTextContainer
          $withpadding={true}
          text='(필수) 제 3자 제공 동의'
          isOn={requireTerm3}
          onToggle={() => SetRequireTerm3(!requireTerm3)}
        />
        <AgreeTextContainer
          $withpadding={true}
          text='(선택) 제 3자 제공, 처리위탁 동의'
          isOn={optionTerm1}
          onToggle={() => SetOptionTerm1(!optionTerm1)}
        />
        <AgreeTextContainer
          $withpadding={true}
          text='(선택) 광고 수신 동의'
          isOn={optionTerm2}
          onToggle={() => SetOptionTerm2(!optionTerm2)}
        />
      </BodyContainer>
      
      
      {/* 하단 */}
      <InfoFooter
        background={(
          requireTerm &&
          requireTerm2 &&
          requireTerm3) ? '#A57BDB' : '#EEEEEE'
        }
        btnkClick={btnNextClick}
      />
    </AgreeContainer>
  );
};

export default Agree;
